.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
    color: #18b1ff;
}
